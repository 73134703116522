import ErrorPage from 'components/ErrorPage'
import React from 'react'

interface Props {}

const NotFoundPage = ({}: Props) => {
  return (
    <ErrorPage code={404} message="Laman yang Anda tuju tiada ditemukan." />
  )
}

export default NotFoundPage
