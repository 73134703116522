import { NextSeo } from 'next-seo'
import Link from 'next/link'
import React from 'react'
import BaseFrontend from './frontend/BaseFrontend'

interface Props {
  code?: number
  message?: string
}

const ErrorPage = ({ code, message }: Props) => {
  return (
    <BaseFrontend menus={[]}>
      <NextSeo title="Halaman tidak ditemukan" />
      <div className="container m-auto pt-8 pb-32 md:py-32 px-4 md:px-4">
        <h1 className="font-semibold text-6xl mb-2">{code || 404}</h1>
        <p className="text-xl mb-8">
          {message || 'Laman yang Anda tuju tiada ditemukan.'}
        </p>
        <Link href="/">
          <a className="px-4 py-2 border border-blue-100 rounded text-blue-100 hover:bg-blue-100 hover:text-white transition ease-in-out duration-200">
            Kembali ke halaman utama
          </a>
        </Link>
      </div>
    </BaseFrontend>
  )
}

export default ErrorPage
