import BaseFrontend from 'components/frontend/BaseFrontend'
import PrimaryHead from 'components/frontend/PrimaryHead'
import { Setting } from 'entity'
import { GetServerSideProps } from 'next'
import { NextSeo } from 'next-seo'
import React from 'react'
import { getRepository } from 'typeorm'
import fetchBaseFrontendProps from 'util/fetchBaseFrontendProps'
import getMediaURL from 'util/getMedia'
import nextMiddleware from 'util/nextMiddleware'
import NotFoundPage from './404'

interface FrameInterface {
  path: string
  address: string
}

interface SubPagesInterface {
  path: string
  address: string
  list_cards: {
    title: string
    text: string
    link: string
    background: string
    backgroundImage: string
    color: string
    icon: string
    id: string
  }[]
}

interface Props {
  frame: FrameInterface
  page: SubPagesInterface
}

const RootsPage = ({ frame, page }: Props) => {
  if (page) {
    return (
      <BaseFrontend menus={[]}>
        <NextSeo title={page.address} />
        <PrimaryHead
          title={page.address}
          withIcon={
            <span className="w-12 md:w-24">
              <svg
                // width="96"
                // height="96"
                viewBox="0 0 96 96"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.1"
                  d="M12 52H44V12H12V52ZM12 84H44V60H12V84ZM52 84H84V44H52V84ZM52 12V36H84V12H52Z"
                  fill="white"
                />
              </svg>
            </span>
          }
        />
        <section
          id="primary-content"
          className="mt-6 md:mt-10 container m-auto px-4 md:px-0"
        >
          <div className="grid grid-cols-1 md:grid-cols-4 gap-4 md:gap-8 mb-24">
            {page.list_cards.map((item, index) => (
              <div
                key={index}
                className="flex flex-col bg-grey-100 overflow-hidden rounded-md relative hover:shadow-lg transition-shadow ease-in-out duration-200"
              >
                <div
                  className="bg-top h-20 relative"
                  style={{ backgroundColor: '#' + item.background }}
                >
                  <div
                    className="absolute w-full h-full opacity-50 bg-cover bg-center"
                    style={{
                      backgroundImage: `url(${getMediaURL(
                        'images',
                        'portal',
                        'backgrounds',
                        item.backgroundImage
                      )})`,
                    }}
                  />
                  <div
                    className="w-16 h-16 absolute left-0 bottom-0 bg-white shadow-md rounded-full -mb-8 ml-6 flex justify-center items-center text-2xl"
                    style={{ color: '#' + item.color }}
                  >
                    {/^(fa|far|fas|fab)/.test(item.icon) ? (
                      <em className={item.icon} />
                    ) : (
                      <img
                        src={getMediaURL(
                          'images',
                          'portal',
                          'icons',
                          item.icon
                        )}
                        alt={item.title}
                      />
                    )}
                  </div>
                </div>
                <div className="content p-6 pt-12 flex-1">
                  <h3 className="mb-2 font-semibold text-lg">{item.title}</h3>
                  <p className="opacity-75">{item.text}</p>
                </div>
                <div className="p-6">
                  <a
                    href={item.link}
                    className="stretched-link text-blue-100 font-semibold"
                  >
                    Lihat lebih lanjut
                  </a>
                </div>
              </div>
            ))}
          </div>
        </section>
      </BaseFrontend>
    )
  }

  if (frame) {
    /**
     * Frame Sections
     *
     *
     *
     */
    return (
      <div>
        <NextSeo title="Website Resmi Direktorat Sekolah Menengah Kejuruan" />
        <iframe
          src={frame.address}
          style={{ border: 0, width: '100vw', height: '100vh' }}
        />
      </div>
    )
  }

  return <NotFoundPage />
}

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  try {
    await nextMiddleware(ctx)

    const baseFrontend = await fetchBaseFrontendProps(ctx)

    const extframes = await getRepository(Setting).find({
      where: [{ key: 'EXTERNAL_FRAMES_DATA' }, { key: 'SUB_PAGES_JSON' }],
      cache: true,
    })

    /**
     * @todo External frame maybe need redis for optimization
     *
     *
     */
    const frames = JSON.parse(
      extframes.find((item) => item.key === 'EXTERNAL_FRAMES_DATA').value
    ) as FrameInterface[]

    const subPages = JSON.parse(
      extframes.find((item) => item.key === 'SUB_PAGES_JSON').value
    ) as SubPagesInterface[]

    const frame = frames.find((e) => e.path === ctx.req.url.replace(/^\//, ''))
    if (frame) {
      return { props: { ...baseFrontend, frame } }
    }

    const page = subPages.find((e) => e.path === ctx.req.url.replace(/^\//, ''))
    if (page) {
      return { props: { ...baseFrontend, page } }
    }

    /**
     * Otherwise catch to 404 page
     *
     *
     */
    ctx.res.statusCode = 404
    return { props: { ...baseFrontend, frame: null } }
  } catch (e) {
    console.error(e)
  }
  return { props: { frame: null } }
}

export default RootsPage
