import { Form, Formik } from 'formik'
import { useRouter } from 'next/router'
import React from 'react'
import { getQueryParams, toQueryParams } from 'util/fetcher'

interface Props {
  target: string
  pathname?: string
  children: React.ReactNode
}

export const FilterComponent = ({ pathname, target, children }: Props) => {
  const router = useRouter()
  const onFilter = async (values) => {
    values.page = 1
    target = target + toQueryParams({ ...values })
    router.push(pathname ? pathname : target, pathname ? target : undefined)
  }

  return (
    <Formik
      initialValues={{ q: '', ...getQueryParams() }}
      onSubmit={onFilter}
      enableReinitialize
    >
      <Form>{children}</Form>
    </Formik>
  )
}
