import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FilterComponent } from 'components/FilterComponent'
import { getIn, useFormikContext } from 'formik'
import { Submit } from 'formstrap'
import { useRouter } from 'next/router'
import React, { Fragment, useMemo } from 'react'

interface Props {
  title: string
  withSearch?: boolean
  withIcon?: React.ReactNode
}

const SearchComponent = () => {
  const { values, handleChange } = useFormikContext()

  return (
    <Fragment>
      <input
        type="search"
        className="hidden md:block border-2 border-transparent transition ease-in-out duration-100 py-2 px-4 leading-tight rounded-md appearance-none h-auto bg-gray-100 bg-opacity-25 placeholder-white placeholder-opacity-50 pr-12 focus:border-white focus:border-opacity-50"
        name="q"
        value={getIn(values, 'q')}
        onChange={handleChange}
        placeholder="Pencarian"
      />
      <Submit
        color=""
        className="absolute right-0 top-0 bg-transparent hover:bg-transparent text-white"
      >
        <FontAwesomeIcon icon={faSearch} />
      </Submit>
    </Fragment>
  )
}

const PrimaryHead = ({ title, withSearch, withIcon }: Props) => {
  const { asPath, pathname } = useRouter()

  const target = useMemo(() => {
    const url = new URL('http://example.com' + asPath)
    return url.pathname
  }, [asPath])

  return (
    <section
      id="primary-header"
      className="bg-blue-300 text-white mb-2 md:mb-4"
    >
      <div className="container m-auto">
        <div className="flex px-4 py-6 md:h-40 md:px-0">
          <div className="flex-1 flex items-center">
            <h1 className="text-2xl md:text-4xl font-semibold md:w-9/12">
              {title}
            </h1>
          </div>
          <div className="flex items-center">
            {withIcon}
            {withSearch && (
              <FilterComponent pathname={pathname} target={target}>
                <SearchComponent />
              </FilterComponent>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default PrimaryHead
